import { useCallback, useEffect, useRef, useState } from "react";
import { fetchData, putData, putFormData } from "../../helpers/endpoints";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./style.scss";
import { Layout } from "../../components/layout";
import Questionnaire from "./components/Questionnaire";
import Documents from "./components/Documents";
import PatientList from "./components/PatientList";
import { useAssets, useMobile } from "../../hooks";
import { CustomButton } from "../../components/CustomButton";
import { useParams } from "react-router-dom";
import Appointments from "./components/Appointment";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import Modal from "react-modal";
import imageCompression from "browser-image-compression";
import Services from "./components/Services";
import { useNavigate, useLocation } from "react-router-dom";
import MyModal from "./components/modal/Mymodal";
import { useConfig } from "../../hooks/useConfig";
import { useClickOutside } from "../../hooks/useClickOutside";
import Cropper from "react-easy-crop";
import { Slider, Button, message } from "antd";

const Dashboard = () => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: "11",
    },
  };
  const navigator = useNavigate();
  const pathname = useLocation().pathname;
  const { patientId, defultTab, serviceId } = useParams();
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [name, setName] = useState("");
  const [user, setuser] = useState("");
  const [mrn, setMrn] = useState("");
  const [photo, setPhoto] = useState("");
  const [showNotif, setshowNotif] = useState(false);
  const notifRef = useRef();
  useClickOutside(notifRef, () => {
    setshowNotif(false);
  });
  const [imgSrc, setImgSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [containerStyle, setContainerStyle] = useState({
    width: "200px",
    height: "200px",
  });
  const [tabIndex, setTabIndex] = useState(
    pathname == "/dashboard/0"
      ? 0
      : defultTab
      ? defultTab == "form"
        ? 1
        : defultTab == "document"
        ? 2
        : defultTab == "service" || defultTab == "purchase"
        ? 4
        : defultTab == "appointment"
        ? 3
        : 4
      : 0
  );
  const [show, setShow] = useState(false);
  const [hasPatient, setHasPatient] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [notifications, setNotifications] = useState([
    {
      html: (
        <div>You have a new reply on [Service Name], Step [Step Number].</div>
      ),
      date: new Date(),
      button: "Start",
    },
    {
      html: (
        <div>You have a new reply on [Service Name], Step [Step Number].</div>
      ),
      date: new Date(),
      button: "Start",
    },
    {
      html: (
        <div>You have a new reply on [Service Name], Step [Step Number].</div>
      ),
      date: new Date().toDateString(),
      button: "Show",
    },
    {
      html: (
        <div>You have a new reply on [Service Name], Step [Step Number].</div>
      ),
      date: new Date().toDateString(),
      button: "Show",
    },
    {
      html: (
        <div>You have a new reply on [Service Name], Step [Step Number].</div>
      ),
      date: new Date().toDateString(),
      button: "Show",
    },
    {
      html: (
        <div>You have a new reply on [Service Name], Step [Step Number].</div>
      ),
      date: new Date().toDateString(),
      button: "Show",
    },
  ]);

  const { getFile } = useAssets();
  const isMobile = useMobile();
  const { getUserConfig } = useConfig();

  const getNotifications = async () => {
    setIsLoading(true);
    var res = await fetchData("notification/app");
    if (res.status == 200) {
      setNotifications(res.data.data);
    }
    setIsLoading(false);
  };
  const seenNotif = async (id, reload = false) => {
    setIsLoading(true);
    var res = await putData("notification/app/" + id + "/seen");
    if (res.status == 200 && reload) {
      await getNotifications();
    }
    setIsLoading(false);
  };
  const seenAllNotif = async () => {
    for (var i in notifications) {
      var notif = notifications[i];
      if (notif.priority != "argent" && !notif.seenAt) {
        await seenNotif(notif.id);
      }
    }
    await getNotifications();
  };
  const uploadImage = async () => {
    setIsLoading(true);
    if (croppedImage) {
      const croppedImgBlob = await fetch(croppedImage).then((r) => r.blob());
      const file = new File([croppedImgBlob], "cropped_image.jpg", {
        type: "image/jpeg",
      });

      if (file.size > 5 * 1024 * 1024) {
        Swal.fire({
          title: "",
          text: "Please upload a correct file.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
          };
          const compressedFile = await imageCompression(file, options);

          let formData = new FormData();
          formData.append("file", compressedFile);

          const res = await putFormData(
            "patient/" + patientId + "/photo/upload",
            formData
          );

          if (res.status === 200) {
            setIsOpen(false);
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "Image uploaded successfully!",
              icon: "success",
              confirmButtonText: "Ok",
            });
            await fetchUser();
          } else {
            Swal.fire({
              title: "",
              text: res,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        } catch (error) {
          console.error("Error during upload:", error);
          Swal.fire({
            title: "",
            text: "Error uploading the image.",
            icon: "error",
            confirmButtonText: "Ok",
          });
          setIsLoading(false);
        }
      }
    } else {
      Swal.fire({
        title: "",
        text: "Please crop the image before uploading.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setIsLoading(false);
    }
  };

  const fileTypes = ["JPG", "PNG"];

  const handleFileSelect = (file) => {
    if (file.size / 1024 / 1024 > 5) {
      message.error("Image must be smaller than 5MB!");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        // Dynamically adjust container size based on aspect ratio
        if (img.width === img.height) {
          setContainerStyle({
            width: "200px",
            height: "200px",
          });
        } else if (img.width > img.height) {
          setContainerStyle({
            width: "400px",
            height: "200px",
          });
        } else {
          setContainerStyle({
            width: "200px",
            height: "400px",
          });
        }
      };
      img.src = reader.result;
      setImgSrc(reader.result);
      setIsCropping(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCropComplete = async () => {
    try {
      const croppedImg = await getCroppedImg(imgSrc, croppedAreaPixels);
      setCroppedImage(croppedImg);
      setIsCropping(false);
    } catch (error) {
      console.error("Crop error:", error);
    }
  };

  async function getCroppedImg(imageSrc, pixelCrop) {
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = url;
      });

    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob));
      }, "image/jpeg");
    });
  }

  async function fetchUser() {
    if (patientId == 0) {
      setHasPatient(false);
      setPhoto("");
      setEmail("");
      setName(" ");
      var res = await fetchData("user");
      if (res.status === 200) {
        setuser(res.data.data);
      }
    } else {
      setHasPatient(true);
      var res = await fetchData("patient/" + patientId);
      if (res.status != 200 || !res.data.data.verifiedAt) {
        navigator("/dashboard/0");
        return;
      }
      setEmail(
        res.data.data.relationship === "me" ||
          res.data.data.relationship === "ME"
          ? ""
          : res.data.data.relationship
      );
      getUserConfig();
      var res2 = JSON.parse(localStorage.getItem("config"));

      setName(res.data.data.firstName + " " + res.data.data.lastName);
      setMrn(res.data.data.clinicalCode);
      setPhoto(
        res.data.data.photo
          ? res2.data.data.storage.bucket.url + "/" + res.data.data.photo
          : null
      );
      var res2 = await fetchData("user");

      if (res2.status === 200) {
        setuser(res2.data.data);
        setEmail2(res2.data.data.email);
      }
    }
  }
  useEffect(() => {
    if (showNotif === false) {
      seenAllNotif();
    }
  }, [showNotif]);

  useEffect(() => {
    fetchUser();
    getNotifications();
    if (pathname == "/dashboard/0") {
      setTabIndex(0);
    } else {
      if (defultTab == "service") {
        setTabIndex(4);
      }
      if (!defultTab) {
        setTabIndex(4);
      }
    }
  }, [patientId]);
  return (
    <Layout>
      <div className="dashboard">
        <div className="dashboard-profile d-flex flex-row gap-3" dir="ltr">
          {patientId != 0 && (
            <div
              onClick={() => {
                setIsOpen(true);
              }}
              className="d-flex flex-column "
              style={{ zIndex: "3" }}
            >
              <img
                onClick={() => {
                  setIsOpen(true);
                }}
                style={
                  photo
                    ? isMobile
                      ? {
                          borderRadius: "50%",
                          marginBottom: "-24px",
                          width: "50px",
                          height: "50px",
                        }
                      : {
                          borderRadius: "50%",
                          marginBottom: "-24px",
                          width: "100px",
                          height: "100px",
                        }
                    : { marginBottom: "-24px" }
                }
                src={photo ? photo : getFile("LightProfile")}
                alt="profile"
              />
              <svg
                onClick={() => {
                  setIsOpen(true);
                }}
                style={
                  isMobile
                    ? {
                        marginLeft: "17px",
                        width: "18",
                        height: "18",
                        marginTop: "5px",
                      }
                    : {
                        marginTop: "-25px",
                        marginLeft: "80px",
                      }
                }
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_b_2932_105653)">
                  <circle
                    cx="12"
                    cy="12.9979"
                    r="12"
                    fill="rgba(255, 255, 255, 1)"
                  />
                  <circle
                    cx="12"
                    cy="12.9979"
                    r="11"
                    stroke="#212529"
                    stroke-opacity="1"
                  />
                </g>
                <path
                  d="M15.4677 12.0617L15.6517 12.2455L8.89883 18.9984H6V16.0997L12.7528 9.3465L15.2839 11.8776L15.4677 12.0617Z"
                  fill="#18324E"
                />
                <path
                  d="M17.7497 10.1473L16.2641 11.6329L13.3652 8.73409L14.8509 7.24846C15.185 6.91441 15.7288 6.91441 16.0629 7.24846L17.7498 8.93542C18.0837 9.26934 18.0837 9.81302 17.7497 10.1473H17.7497Z"
                  fill="#18324E"
                />
                <defs>
                  <filter
                    id="filter0_b_2932_105653"
                    x="-25"
                    y="-24.0021"
                    width="74"
                    height="74"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                      in="BackgroundImageFix"
                      stdDeviation="12.5"
                    />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_2932_105653"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_2932_105653"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_2932_105653"
                    x1="4.79677e-07"
                    y1="24.1531"
                    x2="11.6348"
                    y2="6.92536"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" stop-opacity="0.4" />
                    <stop offset="1" stop-color="white" stop-opacity="0.1" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          )}
          {patientId != 0 && (
            <div className="d-flex flex-column pb-3">
              <span className="emailSpan">{name}</span>
              {email && <span className="nameSpan">{email}</span>}
            </div>
          )}

          {isMobile && (
            <img
              src={!show ? getFile("menue") : getFile("DarkClose")}
              style={
                isMobile
                  ? {
                      width: "24px",
                      position: "absolute",
                      right: "12%",

                      zIndex: 1,
                    }
                  : {
                      width: "24px",
                      position: "absolute",
                      right: "12%",
                      marginTop: "8%",
                      zIndex: 1,
                    }
              }
              className="menuIcon"
              onClick={async () => {
                setShow(!show);
              }}
            />
          )}
          {show && (
            <div className="mobile-tab d-flex flex-column">
              {patientId == 0 && (
                <CustomButton
                  title={"Patient’s list"}
                  onClick={() => {
                    setTabIndex(0);
                  }}
                  uiType={tabIndex != 0 ? "outlined2" : "primary"}
                  fullWidth
                />
              )}

              {hasPatient && patientId != 0 && (
                <>
                  <CustomButton
                    title={"Questionnaire"}
                    onClick={() => {
                      setTabIndex(1);

                      navigator("/dashboard/" + patientId + "/form");
                      setShow(false);
                    }}
                    uiType={tabIndex != 1 ? "outlined2" : "primary"}
                    fullWidth
                  />

                  <CustomButton
                    title={"Documentation"}
                    onClick={() => {
                      setTabIndex(2);
                      navigator("/dashboard/" + patientId + "/document");
                      setShow(false);
                    }}
                    uiType={tabIndex != 2 ? "outlined2" : "primary"}
                    fullWidth
                  />
                  <CustomButton
                    title={"Appointments"}
                    onClick={() => {
                      setTabIndex(3);

                      navigator("/dashboard/" + patientId + "/appointment");
                      setShow(false);
                    }}
                    uiType={tabIndex != 3 ? "outlined2" : "primary"}
                    fullWidth
                  />
                  <CustomButton
                    title={"Services"}
                    onClick={() => {
                      setTabIndex(4);

                      navigator("/dashboard/" + patientId + "/service");
                      setShow(false);
                    }}
                    uiType={tabIndex != 4 ? "outlined2" : "primary"}
                    fullWidth
                  />
                </>
              )}
            </div>
          )}
        </div>
        {isMobile && <div className="divider" />}
        <Tabs
          direction={"rtl"}
          selectedIndex={tabIndex}
          onSelect={(index) => {
            if (index != 0) {
              setTabIndex(index);
              var temp =
                index == 4
                  ? "service" || "purchase"
                  : index == 1
                  ? "form"
                  : index == 2
                  ? "document"
                  : "appointment";

              navigator("/dashboard/" + patientId + "/" + temp);
            }
          }}
        >
          {!isMobile && (
            <TabList>
              {patientId == 0 && (
                <Tab style={{ cursor: "default" }}>Patients info</Tab>
              )}
              {hasPatient && patientId != 0 && (
                <>
                  <Tab>
                    {showNotif ? (
                      <svg
                        width="44"
                        height="33"
                        viewBox="0 0 44 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="notification_svg"
                        style={{
                          position: "relative",
                          marginBottom: "-10px",
                          marginRight: "-20px",
                        }}
                        onClick={async () => {
                          setshowNotif(!showNotif);
                          if (showNotif) {
                            await seenAllNotif();
                          }
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.7701 26.1002H13.2277C13.7813 27.0563 14.8158 27.7002 15.999 27.7002C17.1822 27.7002 18.2167 27.0563 18.7702 26.1002H18.7701ZM9.30363 25.3002H22.6938C23.2506 25.3002 23.7674 25.0114 24.0579 24.537C24.349 24.0625 24.3722 23.4706 24.1202 22.9747L23.1986 21.1634V14.1001C23.1986 10.8314 21.0379 8.79771 18.3721 8.05439C18.3898 7.93921 18.3986 7.82064 18.3986 7.69993C18.3986 6.37513 17.3234 5.29993 15.9986 5.29993C14.6738 5.29993 13.5986 6.37513 13.5986 7.69993C13.5986 7.82064 13.6073 7.93921 13.625 8.05439C10.9593 8.79761 8.79846 10.8312 8.79846 14.1001V21.1634L7.87686 22.9747C7.6249 23.4706 7.64812 24.0625 7.93919 24.537C8.22972 25.0115 8.7465 25.3002 9.3033 25.3002H9.30363Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.796 7.76985C16.7985 7.74735 16.7992 7.72342 16.7992 7.70021C16.7992 7.2586 16.4408 6.90021 15.9992 6.90021C15.5576 6.90021 15.1992 7.2586 15.1992 7.70021C15.1992 7.72342 15.1999 7.74735 15.2024 7.76985C15.4664 7.74663 15.7328 7.73538 15.9992 7.73538C16.2657 7.73538 16.5321 7.74663 16.796 7.76985H16.796Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M39 13.0141L34 19.9857L39 19.071L44 19.9857L39 13.0141Z"
                          fill="black"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="44"
                        height="33"
                        viewBox="0 0 44 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="notification_svg"
                        style={{
                          position: "relative",
                          marginBottom: "-10px",
                          marginRight: "-20px",
                        }}
                        onClick={async () => {
                          setshowNotif(!showNotif);
                          if (showNotif) {
                            await seenAllNotif();
                          }
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.7701 26.1002H13.2277C13.7813 27.0563 14.8158 27.7002 15.999 27.7002C17.1822 27.7002 18.2167 27.0563 18.7702 26.1002H18.7701ZM9.30363 25.3002H22.6938C23.2506 25.3002 23.7674 25.0114 24.0579 24.537C24.349 24.0625 24.3722 23.4706 24.1202 22.9747L23.1986 21.1634V14.1001C23.1986 10.8314 21.0379 8.79771 18.3721 8.05439C18.3898 7.93921 18.3986 7.82064 18.3986 7.69993C18.3986 6.37513 17.3234 5.29993 15.9986 5.29993C14.6738 5.29993 13.5986 6.37513 13.5986 7.69993C13.5986 7.82064 13.6073 7.93921 13.625 8.05439C10.9593 8.79761 8.79846 10.8312 8.79846 14.1001V21.1634L7.87686 22.9747C7.6249 23.4706 7.64812 24.0625 7.93919 24.537C8.22972 25.0115 8.7465 25.3002 9.3033 25.3002H9.30363Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.796 7.76985C16.7985 7.74735 16.7992 7.72342 16.7992 7.70021C16.7992 7.2586 16.4408 6.90021 15.9992 6.90021C15.5576 6.90021 15.1992 7.2586 15.1992 7.70021C15.1992 7.72342 15.1999 7.74735 15.2024 7.76985C15.4664 7.74663 15.7328 7.73538 15.9992 7.73538C16.2657 7.73538 16.5321 7.74663 16.796 7.76985H16.796Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M39 19.9857L34 13.0141L39 13.9287L44 13.0141L39 19.9857Z"
                          fill="black"
                        />
                      </svg>
                    )}

                    {showNotif && (
                      <div ref={notifRef} dir="ltr" className="notif-wrap py-3">
                        <h2
                          className="px-4"
                          style={{ textAlign: "left", width: "100%" }}
                        >
                          Notifications
                        </h2>
                        <div
                          className="my-2"
                          style={{
                            height: "1px",
                            width: "100%",
                            backgroundColor: "#E1E1E1",
                          }}
                        />
                        <div
                          className="px-3"
                          style={{
                            overflowY: "scroll",
                            width: "100%",
                            height: "95%",
                          }}
                        >
                          {" "}
                          {notifications.filter((notif) => {
                            return (
                              new Date() - new Date(notif.createdAt) <
                              24 * 60 * 60 * 1000
                            );
                          }).length > 0 && (
                            <h2
                              className="my-3"
                              style={{
                                textAlign: "left",
                                width: "100%",
                                color: "#A1813A",
                              }}
                            >
                              Today
                            </h2>
                          )}
                          {notifications
                            .filter((notif) => {
                              return (
                                new Date() - new Date(notif.createdAt) <
                                24 * 60 * 60 * 1000
                              );
                            })
                            .map((notif) => {
                              return (
                                <div
                                  dir="ltr"
                                  className="col-12 flex-row gap-1 d-flex align-items-center "
                                >
                                  <div
                                    style={
                                      notif.seenAt
                                        ? {}
                                        : { backgroundColor: "white" }
                                    }
                                    className="notif_card d-flex flex-column"
                                  >
                                    <div
                                      style={{ alignItems: "center" }}
                                      className="d-flex flex-row col-12 px-1 py-1"
                                    >
                                      <h2
                                        style={{ lineHeight: "15px" }}
                                        className="col-10"
                                      >
                                        {notif.text}
                                      </h2>
                                      {notif.actionUrl && (
                                        <CustomButton
                                          uiType="outlined-small"
                                          title={"Take Action"}
                                          additionalClassNames="col-2"
                                          onClick={() => {
                                            var link =
                                              document.createElement("a");
                                            link.href = notif.actionUrl;
                                            link.click();
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "8px",
                                        color: "#4C4C4C",
                                        height: "15px",
                                        width: "100%",
                                        textAlign: "right",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        display: "flex",
                                      }}
                                    >
                                      {new Date(notif.createdAt)
                                        .toDateString()
                                        .substring(0, 10) +
                                        " , " +
                                        new Date(notif.createdAt)
                                          .toLocaleTimeString()
                                          .substring(0, 4) +
                                        new Date(notif.createdAt)
                                          .toLocaleTimeString()
                                          .substring(7, 11)}
                                    </div>
                                  </div>
                                  <div style={{ width: "4%" }}>
                                    {
                                      // notif.priority == "argent" &&
                                      notif.seenAt ? (
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.99933 0C3.58552 0 0 3.58686 0 8.00067C0 12.4145 3.58552 16 7.99933 16C12.4131 16 16 12.4145 16 8.00067C16 3.58686 12.4131 0 7.99933 0ZM11.5158 4.37036C11.8092 4.37036 12.1013 4.48088 12.323 4.70195C12.7658 5.14471 12.7658 5.87564 12.323 6.31906L7.47503 11.1671C7.03227 11.6098 6.30068 11.6098 5.85792 11.1671L3.70331 9.01177C3.26055 8.56901 3.26055 7.83941 3.70331 7.39598C4.14607 6.95321 4.87567 6.95321 5.31911 7.39598L6.66582 8.74334L10.7073 4.70259C10.929 4.48022 11.2224 4.37036 11.5158 4.37036Z"
                                            fill="#248C00"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          onClick={async () => {
                                            await seenNotif(notif.id, true);
                                          }}
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.99933 0C3.58552 0 0 3.58686 0 8.00067C0 12.4145 3.58552 16 7.99933 16C12.4131 16 16 12.4145 16 8.00067C16 3.58686 12.4131 0 7.99933 0ZM11.5158 4.37036C11.8092 4.37036 12.1013 4.48088 12.323 4.70195C12.7658 5.14471 12.7658 5.87564 12.323 6.31906L7.47503 11.1671C7.03227 11.6098 6.30068 11.6098 5.85792 11.1671L3.70331 9.01177C3.26055 8.56901 3.26055 7.83941 3.70331 7.39598C4.14607 6.95321 4.87567 6.95321 5.31911 7.39598L6.66582 8.74334L10.7073 4.70259C10.929 4.48022 11.2224 4.37036 11.5158 4.37036Z"
                                            fill="#D1D1D1"
                                          />
                                        </svg>
                                      )
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          {notifications.filter((notif) => {
                            return (
                              new Date() - new Date(notif.createdAt) >
                                24 * 60 * 60 * 1000 &&
                              new Date() - new Date(notif.createdAt) <
                                48 * 60 * 60 * 1000
                            );
                          }).length > 0 && (
                            <h2
                              className="my-3"
                              style={{
                                textAlign: "left",
                                width: "100%",
                                color: "#A1813A",
                              }}
                            >
                              Yesterday
                            </h2>
                          )}
                          {notifications
                            .filter((notif) => {
                              return (
                                new Date() - new Date(notif.createdAt) >
                                  24 * 60 * 60 * 1000 &&
                                new Date() - new Date(notif.createdAt) <
                                  48 * 60 * 60 * 1000
                              );
                            })
                            .map((notif) => {
                              return (
                                <div
                                  dir="ltr"
                                  className="col-12 flex-row gap-1 d-flex align-items-center "
                                >
                                  <div
                                    style={
                                      notif.seenAt
                                        ? {}
                                        : { backgroundColor: "white" }
                                    }
                                    className="notif_card d-flex flex-column"
                                  >
                                    <div
                                      style={{ alignItems: "center" }}
                                      className="d-flex flex-row col-12 px-1 py-1"
                                    >
                                      <h2
                                        style={{ lineHeight: "15px" }}
                                        className="col-10"
                                      >
                                        {notif.text}
                                      </h2>
                                      {notif.actionUrl && (
                                        <CustomButton
                                          uiType="outlined-small"
                                          title={"Take Action"}
                                          additionalClassNames="col-2"
                                          onClick={() => {
                                            var link =
                                              document.createElement("a");
                                            link.href = notif.actionUrl;
                                            link.click();
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "8px",
                                        color: "#4C4C4C",
                                        height: "15px",
                                        width: "100%",
                                        textAlign: "right",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        display: "flex",
                                      }}
                                    >
                                      {new Date(notif.createdAt)
                                        .toDateString()
                                        .substring(0, 10) +
                                        " , " +
                                        new Date(notif.createdAt)
                                          .toLocaleTimeString()
                                          .substring(0, 4) +
                                        new Date(notif.createdAt)
                                          .toLocaleTimeString()
                                          .substring(7, 11)}
                                    </div>
                                  </div>
                                  <div style={{ width: "4%" }}>
                                    {
                                      // notif.priority == "argent" &&
                                      notif.seenAt ? (
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.99933 0C3.58552 0 0 3.58686 0 8.00067C0 12.4145 3.58552 16 7.99933 16C12.4131 16 16 12.4145 16 8.00067C16 3.58686 12.4131 0 7.99933 0ZM11.5158 4.37036C11.8092 4.37036 12.1013 4.48088 12.323 4.70195C12.7658 5.14471 12.7658 5.87564 12.323 6.31906L7.47503 11.1671C7.03227 11.6098 6.30068 11.6098 5.85792 11.1671L3.70331 9.01177C3.26055 8.56901 3.26055 7.83941 3.70331 7.39598C4.14607 6.95321 4.87567 6.95321 5.31911 7.39598L6.66582 8.74334L10.7073 4.70259C10.929 4.48022 11.2224 4.37036 11.5158 4.37036Z"
                                            fill="#248C00"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          onClick={async () => {
                                            await seenNotif(notif.id, true);
                                          }}
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.99933 0C3.58552 0 0 3.58686 0 8.00067C0 12.4145 3.58552 16 7.99933 16C12.4131 16 16 12.4145 16 8.00067C16 3.58686 12.4131 0 7.99933 0ZM11.5158 4.37036C11.8092 4.37036 12.1013 4.48088 12.323 4.70195C12.7658 5.14471 12.7658 5.87564 12.323 6.31906L7.47503 11.1671C7.03227 11.6098 6.30068 11.6098 5.85792 11.1671L3.70331 9.01177C3.26055 8.56901 3.26055 7.83941 3.70331 7.39598C4.14607 6.95321 4.87567 6.95321 5.31911 7.39598L6.66582 8.74334L10.7073 4.70259C10.929 4.48022 11.2224 4.37036 11.5158 4.37036Z"
                                            fill="#D1D1D1"
                                          />
                                        </svg>
                                      )
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          {notifications.filter((notif) => {
                            return (
                              new Date() - new Date(notif.createdAt) >
                              48 * 60 * 60 * 1000
                            );
                          }).length > 0 && (
                            <h2
                              className="my-3"
                              style={{
                                textAlign: "left",
                                width: "100%",
                                color: "#A1813A",
                              }}
                            >
                              Other
                            </h2>
                          )}
                          {notifications
                            .filter((notif) => {
                              return (
                                new Date() - new Date(notif.createdAt) >
                                48 * 60 * 60 * 1000
                              );
                            })
                            .map((notif) => {
                              return (
                                <div
                                  dir="ltr"
                                  className="col-12 flex-row gap-1 d-flex align-items-center "
                                >
                                  <div
                                    style={
                                      notif.seenAt
                                        ? {}
                                        : { backgroundColor: "white" }
                                    }
                                    className="notif_card d-flex flex-column"
                                  >
                                    <div
                                      style={{ alignItems: "center" }}
                                      className="d-flex flex-row col-12 px-1 py-1"
                                    >
                                      <h2
                                        style={{ lineHeight: "15px" }}
                                        className="col-10"
                                      >
                                        {notif.text}
                                      </h2>
                                      {notif.actionUrl && (
                                        <CustomButton
                                          uiType="outlined-small"
                                          title={"Take Action"}
                                          additionalClassNames="col-2"
                                          onClick={() => {
                                            var link =
                                              document.createElement("a");
                                            link.href = notif.actionUrl;
                                            link.click();
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "8px",
                                        color: "#4C4C4C",
                                        height: "15px",
                                        width: "100%",
                                        textAlign: "right",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        display: "flex",
                                      }}
                                    >
                                      {new Date(notif.createdAt)
                                        .toDateString()
                                        .substring(0, 10) +
                                        " , " +
                                        new Date(notif.createdAt)
                                          .toLocaleTimeString()
                                          .substring(0, 4) +
                                        new Date(notif.createdAt)
                                          .toLocaleTimeString()
                                          .substring(7, 11)}
                                    </div>
                                  </div>
                                  <div style={{ width: "4%" }}>
                                    {
                                      // notif.priority == "argent" &&
                                      notif.seenAt ? (
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.99933 0C3.58552 0 0 3.58686 0 8.00067C0 12.4145 3.58552 16 7.99933 16C12.4131 16 16 12.4145 16 8.00067C16 3.58686 12.4131 0 7.99933 0ZM11.5158 4.37036C11.8092 4.37036 12.1013 4.48088 12.323 4.70195C12.7658 5.14471 12.7658 5.87564 12.323 6.31906L7.47503 11.1671C7.03227 11.6098 6.30068 11.6098 5.85792 11.1671L3.70331 9.01177C3.26055 8.56901 3.26055 7.83941 3.70331 7.39598C4.14607 6.95321 4.87567 6.95321 5.31911 7.39598L6.66582 8.74334L10.7073 4.70259C10.929 4.48022 11.2224 4.37036 11.5158 4.37036Z"
                                            fill="#248C00"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          onClick={async () => {
                                            await seenNotif(notif.id, true);
                                          }}
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.99933 0C3.58552 0 0 3.58686 0 8.00067C0 12.4145 3.58552 16 7.99933 16C12.4131 16 16 12.4145 16 8.00067C16 3.58686 12.4131 0 7.99933 0ZM11.5158 4.37036C11.8092 4.37036 12.1013 4.48088 12.323 4.70195C12.7658 5.14471 12.7658 5.87564 12.323 6.31906L7.47503 11.1671C7.03227 11.6098 6.30068 11.6098 5.85792 11.1671L3.70331 9.01177C3.26055 8.56901 3.26055 7.83941 3.70331 7.39598C4.14607 6.95321 4.87567 6.95321 5.31911 7.39598L6.66582 8.74334L10.7073 4.70259C10.929 4.48022 11.2224 4.37036 11.5158 4.37036Z"
                                            fill="#D1D1D1"
                                          />
                                        </svg>
                                      )
                                    }
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </Tab>

                  <Tab>Questionnaire</Tab>
                  <Tab>Documentation</Tab>
                  <Tab>Appointments</Tab>
                  <Tab>Services</Tab>
                </>
              )}
            </TabList>
          )}
          {patientId == 0 ? (
            <TabPanel>
              <PatientList user={user} />
            </TabPanel>
          ) : (
            <>
              {" "}
              <TabPanel></TabPanel>
              <TabPanel>
                <Questionnaire email={email} patientId={patientId} />
              </TabPanel>
              <TabPanel>
                <Documents patientId={patientId} />
              </TabPanel>
              <TabPanel>
                <Appointments
                  patientId={patientId}
                  name={name}
                  mrn={mrn}
                  email={email2}
                />
              </TabPanel>
              <TabPanel>
                <Services
                  patientId={patientId}
                  name={name}
                  mrn={mrn}
                  email={email2}
                  serviceId={serviceId}
                  userId={user.id}
                />
              </TabPanel>
            </>
          )}
        </Tabs>
      </div>

      <MyModal
        isOpen={isOpen}
        setisopen={setIsOpen}
        isLoading={isLoading}
        // width={isMobile ? "" : "50%"}
      >
        <div style={{ padding: "20px", textAlign: "center" }}>
          {!isCropping ? (
            <>
              <p
                style={{
                  display: "block",
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                You can upload up to 5 MB.
              </p>
              <p>
                <FileUploader
                  handleChange={handleFileSelect}
                  name="file"
                  types={fileTypes}
                  maxSize={5}
                />
              </p>
              {croppedImage && (
                <div style={{ textAlign: "center", padding: "10px" }}>
                  <img
                    src={croppedImage}
                    alt="Cropped"
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              )}
              <div style={{ marginTop: "20px" }}>
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={uploadImage}
                  disabled={!croppedImage}
                >
                  Done
                </Button>
              </div>
            </>
          ) : (
            <div>
              <div
                style={{
                  ...containerStyle,
                  position: "relative",
                  background: "#333",
                  margin: "0 auto",
                }}
              >
                <Cropper
                  image={imgSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={(_, croppedAreaPixels) =>
                    setCroppedAreaPixels(croppedAreaPixels)
                  }
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Slider
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(value) => setZoom(value)}
                  tooltip={{ formatter: null }}
                />
                <Button
                  type="primary"
                  onClick={handleCropComplete}
                  style={{ marginTop: "10px" }}
                  size="large"
                  loading={isLoading}
                >
                  Crop
                </Button>
              </div>
            </div>
          )}
        </div>
      </MyModal>
    </Layout>
  );
};

export default Dashboard;
